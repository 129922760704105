import {useState} from "react";
import "../CssFiles/Welcome.css";
import Industries from "../Components/Industries";
import Counter from "../Components/Counter";
import Certification from "../Components/Certification";
import WhyUsSolution from "../Components/WhyUsSolution";
// import Blogs from '../Components/Blogs';
import Contact from "../Components/Contact";
// import HeaderBanner from '../Includes/HeaderBanner';
// import OurServices from '../Components/OurServices';
// import Address from '../Components/Address';
import Modal from "react-modal";
import { RiCloseFill } from "react-icons/ri";
import LetConnect from "../Components/LetConnect";
import IndustriesServed from "../Components/IndustriesServed";
// import Slider from "../Components/Slider";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const forMobileResponse = window.screen.width;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "0%",
    transform: "translate(-50%, -50%)",
    zIndex: "999999999",
  },
};

const technologiesData = [
  { id: 1, image: "/images/technologies/react.png", para: "React.Js", para2: "",link:"" },
  { id: 2, image: "/images/technologies/nodejs.png", para: "Node.JS", para2: "",link:"" },
  { id: 3, image: "/images/technologies/flutter.png", para: "Flutter", para2: "",link:"" },
  { id: 4, image: "/images/technologies/angular.png", para: "Angular", para2: "",link:""},
  { id: 5, image: "/images/technologies/vuejs.png", para: "Vue.Js", para2: "",link:"" },
  { id: 6, image: "/images/technologies/java.png", para: "Java", para2: "",link:"" },
  { id: 7, image: "/images/technologies/android.png", para: "Android", para2: "",link:"" },
  { id: 8, image: "/images/technologies/javascript.png", para: "JS", para2: "",link:"" },
  { id: 9, image: "/images/technologies/cplusplus.png", para: "C++", para2: "",link:"" },
  { id: 10, image: "/images/technologies/awswhite.png", para: "AWS", para2: "",link:""},
  { id: 11, image: "/images/technologies/github.png", para: "Git Hub", para2: "",link:"" },
  { id: 12, image: "/images/technologies/ios.png", para: "IOS", para2: "",link:"" }
];
const industryData = [
  {
    id: 1,
    image: "/images/ourservice/sales.png",
    para: "Salesforce Development",
    para2: "view now",
    link:"#/services/iot"
  },
  {
    id: 2,
    image: "/images/ourservice/Photo.png",
    para: "Web Development",
    para2: "view now",
    link:"#/services/data_analytics"
  },
  {
    id: 3,
    image: "/images/ourservice/mobile.png",
    para: "Mobile Application",
    para2: "view now",
    link:"#/services/Artificial_Intelligence"
  },
  {
    id: 4,
    image: "/images/ourservice/pen.png",
    para: "UX/UI Designs",
    para2: "view now",
    link:"#/services/blockchain"
  },
  {
    id: 5,
    image: "/images/ourservice/social.png",
    para: "Cloud Services",
    para2: "view now",
    link:"#/services/games_development"
  },
  {
    id: 7,
    image: "/images/ourservice/pic1.jpg",
    para: "IOT",
    para2: "view now",
    link:"#/services/iot"
  },
  {
    id: 8,
    image: "/images/ourservice/pic2.jpg",
    para: "Data Analytics",
    para2: "view now",
    link:"#/services/data_analytics"
  },
  {
    id: 9,
    image: "/images/ourservice/pic3.jpg",
    para: "Artificial Intelligence",
    para2: "view now",
    link:"#/services/Artificial_Intelligence"
  },
  {
    id: 10,
    image: "/images/ourservice/pic4.jpg",
    para: "Blockchain",
    para2: "view now",
    link:"#/services/blockchain"
  },
  {
    id: 11,
    image: "/images/ourservice/pic5.jpg",
    para: "Games Development",
    para2: "view now",
    link:"#/services/games_development"
  },
  {
    id: 12,
    image: "/images/ourservice/pic6.jpg",
    para: "Animations",
    para2: "view now",
    link:"#/services/animations"
  },
  {
    id: 13,
    image: "/images/ourservice/pic7.jpg",
    para: "Mixed AR & VR",
    para2: "view now",
    link:"#/services/mixedReality_ar_&_vr"
  },
  {
    id: 14,
    image: "/images/ourservice/pic8.jpg",
    para: "Business Analysis",
    para2: "view now",
    link:"#/services/business_analysis"
  },
  {
    id: 15,
    image: "/images/ourservice/pic9.jpg",
    para: "QA & Software Testing",
    para2: "view now",
    link:"#/services/qa_software_testing"
  },
  {
    id: 16,
    image: "/images/ourservice/pic10.jpg",
    para: "Cyber Security",
    para2: "view now",
    link:"#/services/cyber_security"
  },
  {
    id: 17,
    image: "/images/ourservice/pic11.jpg",
    para: "Support & Maintenance",
    para2: "view now",
    link:"#/services/support_maintenance"
  },
  {
    id: 18,
    image: "/images/ourservice/pic12.jpg",
    para: "Project Management Trainings",
    para2: "view now",
    link:"#/services/project_management"
  },
];
const industryTitle = { title1: "Our", title2: "Services" };
const technologiesTitle = { title1: "Technologies", title2: "we work on" };

const peragraph =
  "Get in touch with us today to explore opportunities, innovations, and the path to your tech career growth. Let's connect and build something extraordinary together.";

const whyUsSolutionData = { title: `We provide full range global IT Solution`, peragraph: `Choosing Svaak means choosing a partner committed to your success. Let's embark on a journey of innovation, collaboration, and unparalleled results together.`,
  imageOne: `/images/WhyUs1.webp`,
  imageTwo: `/images/WhyUs2.webp`,
 lists: [
  {id:1,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/788db96a7284a1b6902b58e0a754c74cb18bc7e877a5b5f0262ee9769811a9b3?`, title:`On Time Delivery`},
  {id:2,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/788db96a7284a1b6902b58e0a754c74cb18bc7e877a5b5f0262ee9769811a9b3?`, title:`Effective Communication`},
  {id:3,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/a6d84705653580b0a3396116f6885c02192e78f54e411c146fc4e808295d1f2e?`, title:`Optimized Tech Cost`},
  {id:4,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/a6d84705653580b0a3396116f6885c02192e78f54e411c146fc4e808295d1f2e?`, title:`Agile Framework`},
  {id:5,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/a6d84705653580b0a3396116f6885c02192e78f54e411c146fc4e808295d1f2e?`, title:`Access to more Talent`}
] 
};

const IndustriesServedData = {
  title:<span><span className="text-[#21C3E8]">Industries</span> we serve</span>,
  image1:"/images/aeroplane.webp",
  image2:"/images/container.webp",
  lists:[
    [{
      heading:"Healthcare",
      pera:"Svaak Software pioneers healthcare technology solutions, transforming the healthcare industry with innovation and excellence.",
      icon:"/images/bracket/medicine.gif"
    },
    {
      heading:"E-Learning",
      pera:" Svaak Software transforms education with dynamic e-learning solutions, fostering interactive and engaging digital learning experiences.",
      icon:"/images/bracket/online-degree.gif"
    }],
    [{
      heading:"Hospitality",
      pera:"Svaak Software elevates hospitality with cutting-edge technology solutions, enhancing guest experiences and operational efficiency.",
      icon:"/images/bracket/hotel-sign.gif"
    },
    {
      heading:"Transportation & Logistic",
      pera:"Svaak Software revolutionizes transport and logistics with tailored software and web development solutions, optimizing operations for efficiency and precision.",
      icon:"/images/bracket/truck.gif"
    }],
    [{
      heading:"Tourism",
      pera:"Svaak Software redefines tourism with innovative technology solutions, shaping seamless experiences for travellers worldwide.",
      icon:"/images/bracket/airplane.gif"
    },
    {
      heading:"Manufacturing",
      pera:"Svaak Software enhances manufacturing processes with innovative software solutions, optimizing efficiency and precision in every stage of production.",
      icon:"/images/bracket/eco.gif"
    }],
    [{
      heading:"Banking & Finance",
      pera:"Svaak Software revolutionizes banking with advanced software solutions, optimizing operations and delivering a secure, seamless banking experience.",
      icon:"/images/bracket/bank.gif"
    },
    {
      heading:"E-Commerce",
      pera:"Svaak Software powers e-commerce excellence with tailored solutions, delivering seamless online shopping experiences and driving business growth.",
      icon:"/images/bracket/shopping-cart.gif"
    }],
    [{
      heading:"Telecom & Media",
      pera:"SVAAK Software is taking a significant stride in our commitment to innovation and growth. We are on a mission to chart new territories and leave a trail of innovation in the Telecom sector.",
      icon:"/images/bracket/telecommedia.gif"
    },
    {
      heading:"",
      pera:"",
      icon:"",
    }
    ]
  ]
};



const WelcomePage = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const LazyVideo = ({ src }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const videoElement = document.createElement("video");
      videoElement.src = src;
      videoElement.onloadstart = () => {
        setLoaded(true);
      };

      return () => {
        videoElement.onloadstart = null;
      };
    }, [src]);

    return loaded ? <video autoPlay muted loop className="bannerVideoPosition bg-white"><source src={src} type="video/mp4" /></video> : null;
  };

  return (
    <>
      <div className="Welcome_page_bannerDivPosition">
        <div className="overlayBanner"></div>
        <div className="home">
          <LazyVideo src="images/bannerVideo/bannerOne.mp4" />
        </div>
        <div className="hire-us-net">
          <div className="overlap">
            <div className="welcome_page_banner_text">
              <div className="content">
                <div className="sub-content">
                  <p className="elevate-your">
                    <span className="text-wrapper">
                      Elevate your business with
                      {forMobileResponse > 769 ? <br /> : ""}
                        <span> game-changing </span>
                        {forMobileResponse < 769 ? <br /> : ""}
                        <span className="text-[#21C3E8] random-word"></span> <br/>
                      for maximum efficiency.
                    </span>
                  </p>
                  <div>
                      <Link to="/get-call" className="exploreNow_Css">Explore Now</Link>
                  </div>
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <button className="text-end text-3xl font-bold cursor-pointer bg-slate-200 rounded-lg ml-auto block">
                      <RiCloseFill onClick={closeModal} />
                    </button>
                    <LetConnect closeModal={closeModal} />
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span className="span">
        <Industries
          industryData={technologiesData}
          industryTitle={technologiesTitle}
        />
        <Industries industryData={industryData} industryTitle={industryTitle} />

        <IndustriesServed IndustriesServedData={IndustriesServedData} />
        <WhyUsSolution whyUsSolutionData={whyUsSolutionData} />
        <div className="border-y-2 mt-[40px] pb-20">
          <Counter />
        </div>
        <Contact peragraph={peragraph} />
        <Certification />
      </span>

      <Helmet>
        <script src="/customJs/headerdropdown.js" type="text/javascript" />
        <script src="/customJs/randomWordsScript.js" type="text/javascript" />
        <script src="/customJs/fixedHeader.js" type="text/javascript" />
      </Helmet>
    </>
  );
};

export default WelcomePage;


// import {useState} from "react";
// import "../CssFiles/Welcome.css";
// import Industries from "../Components/Industries";
// import Counter from "../Components/Counter";
// import Certification from "../Components/Certification";
// import WhyUsSolution from "../Components/WhyUsSolution";
// // import Blogs from '../Components/Blogs';
// import Contact from "../Components/Contact";
// // import HeaderBanner from '../Includes/HeaderBanner';
// // import OurServices from '../Components/OurServices';
// // import Address from '../Components/Address';
// import Modal from "react-modal";
// import { RiCloseFill } from "react-icons/ri";
// import LetConnect from "../Components/LetConnect";
// import IndustriesServed from "../Components/IndustriesServed";
// // import Slider from "../Components/Slider";
// import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

// const forMobileResponse = window.screen.width;

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "0%",
//     transform: "translate(-50%, -50%)",
//     zIndex: "999999999",
//   },
// };

// const technologiesData = [
//   { id: 1, image: "/images/technologies/react.png", para: "React.Js", para2: "",link:"" },
//   { id: 2, image: "/images/technologies/nodejs.png", para: "Node.JS", para2: "",link:"" },
//   { id: 3, image: "/images/technologies/flutter.png", para: "Flutter", para2: "",link:"" },
//   { id: 4, image: "/images/technologies/angular.png", para: "Angular", para2: "",link:""},
//   { id: 5, image: "/images/technologies/vuejs.png", para: "Vue.Js", para2: "",link:"" },
//   { id: 6, image: "/images/technologies/java.png", para: "Java", para2: "",link:"" },
//   { id: 7, image: "/images/technologies/android.png", para: "Android", para2: "",link:"" },
//   { id: 8, image: "/images/technologies/javascript.png", para: "JS", para2: "",link:"" },
//   { id: 9, image: "/images/technologies/cplusplus.png", para: "C++", para2: "",link:"" },
//   { id: 10, image: "/images/technologies/awswhite.png", para: "AWS", para2: "",link:""},
//   { id: 11, image: "/images/technologies/github.png", para: "Git Hub", para2: "",link:"" },
//   { id: 12, image: "/images/technologies/ios.png", para: "IOS", para2: "",link:"" }
// ];
// const industryData = [
//   {
//     id: 1,
//     image: "/images/ourservice/sales.png",
//     para: "Salesforce Development",
//     para2: "view now",
//     link:"#/services/iot"
//   },
//   {
//     id: 2,
//     image: "/images/ourservice/Photo.png",
//     para: "Web Development",
//     para2: "view now",
//     link:"#/services/data_analytics"
//   },
//   {
//     id: 3,
//     image: "/images/ourservice/mobile.png",
//     para: "Mobile Application",
//     para2: "view now",
//     link:"#/services/Artificial_Intelligence"
//   },
//   {
//     id: 4,
//     image: "/images/ourservice/pen.png",
//     para: "UX/UI Designs",
//     para2: "view now",
//     link:"#/services/blockchain"
//   },
//   {
//     id: 5,
//     image: "/images/ourservice/social.png",
//     para: "Cloud Services",
//     para2: "view now",
//     link:"#/services/games_development"
//   },
//   {
//     id: 7,
//     image: "/images/ourservice/pic1.jpg",
//     para: "IOT",
//     para2: "view now",
//     link:"#/services/iot"
//   },
//   {
//     id: 8,
//     image: "/images/ourservice/pic2.jpg",
//     para: "Data Analytics",
//     para2: "view now",
//     link:"#/services/data_analytics"
//   },
//   {
//     id: 9,
//     image: "/images/ourservice/pic3.jpg",
//     para: "Artificial Intelligence",
//     para2: "view now",
//     link:"#/services/Artificial_Intelligence"
//   },
//   {
//     id: 10,
//     image: "/images/ourservice/pic4.jpg",
//     para: "Blockchain",
//     para2: "view now",
//     link:"#/services/blockchain"
//   },
//   {
//     id: 11,
//     image: "/images/ourservice/pic5.jpg",
//     para: "Games Development",
//     para2: "view now",
//     link:"#/services/games_development"
//   },
//   {
//     id: 12,
//     image: "/images/ourservice/pic6.jpg",
//     para: "Animations",
//     para2: "view now",
//     link:"#/services/animations"
//   },
//   {
//     id: 13,
//     image: "/images/ourservice/pic7.jpg",
//     para: "Mixed AR & VR",
//     para2: "view now",
//     link:"#/services/mixedReality_ar_&_vr"
//   },
//   {
//     id: 14,
//     image: "/images/ourservice/pic8.jpg",
//     para: "Business Analysis",
//     para2: "view now",
//     link:"#/services/business_analysis"
//   },
//   {
//     id: 15,
//     image: "/images/ourservice/pic9.jpg",
//     para: "QA & Software Testing",
//     para2: "view now",
//     link:"#/services/qa_software_testing"
//   },
//   {
//     id: 16,
//     image: "/images/ourservice/pic10.jpg",
//     para: "Cyber Security",
//     para2: "view now",
//     link:"#/services/cyber_security"
//   },
//   {
//     id: 17,
//     image: "/images/ourservice/pic11.jpg",
//     para: "Support & Maintenance",
//     para2: "view now",
//     link:"#/services/support_maintenance"
//   },
//   {
//     id: 18,
//     image: "/images/ourservice/pic12.jpg",
//     para: "Project Management Trainings",
//     para2: "view now",
//     link:"#/services/project_management"
//   },
// ];
// const industryTitle = { title1: "Our", title2: "Services" };
// const technologiesTitle = { title1: "Technologies", title2: "we work on" };

// const peragraph =
//   "Get in touch with us today to explore opportunities, innovations, and the path to your tech career growth. Let's connect and build something extraordinary together.";

// const whyUsSolutionData = { title: `We provide full range global IT Solution`, peragraph: `Choosing Svaak means choosing a partner committed to your success. Let's embark on a journey of innovation, collaboration, and unparalleled results together.`,
//   imageOne: `/images/WhyUs1.webp`,
//   imageTwo: `/images/WhyUs2.webp`,
//  lists: [
//   {id:1,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/788db96a7284a1b6902b58e0a754c74cb18bc7e877a5b5f0262ee9769811a9b3?`, title:`On Time Delivery`},
//   {id:2,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/788db96a7284a1b6902b58e0a754c74cb18bc7e877a5b5f0262ee9769811a9b3?`, title:`Effective Communication`},
//   {id:3,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/a6d84705653580b0a3396116f6885c02192e78f54e411c146fc4e808295d1f2e?`, title:`Optimized Tech Cost`},
//   {id:4,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/a6d84705653580b0a3396116f6885c02192e78f54e411c146fc4e808295d1f2e?`, title:`Agile Framework`},
//   {id:5,icon:`https://cdn.builder.io/api/v1/image/assets/TEMP/a6d84705653580b0a3396116f6885c02192e78f54e411c146fc4e808295d1f2e?`, title:`Access to more Talent`}
// ] 
// };

// const IndustriesServedData = {
//   title:<span><span className="text-[#21C3E8]">Industries</span> we serve</span>,
//   image1:"/images/aeroplane.webp",
//   image2:"/images/container.webp",
//   lists:[
//     [{
//       heading:"Healthcare",
//       pera:"Svaak Software pioneers healthcare technology solutions, transforming the healthcare industry with innovation and excellence.",
//       icon:"/images/bracket/medicine.gif"
//     },
//     {
//       heading:"E-Learning",
//       pera:" Svaak Software transforms education with dynamic e-learning solutions, fostering interactive and engaging digital learning experiences.",
//       icon:"/images/bracket/online-degree.gif"
//     }],
//     [{
//       heading:"Hospitality",
//       pera:"Svaak Software elevates hospitality with cutting-edge technology solutions, enhancing guest experiences and operational efficiency.",
//       icon:"/images/bracket/hotel-sign.gif"
//     },
//     {
//       heading:"Transportation & Logistic",
//       pera:"Svaak Software revolutionizes transport and logistics with tailored software and web development solutions, optimizing operations for efficiency and precision.",
//       icon:"/images/bracket/truck.gif"
//     }],
//     [{
//       heading:"Tourism",
//       pera:"Svaak Software redefines tourism with innovative technology solutions, shaping seamless experiences for travellers worldwide.",
//       icon:"/images/bracket/airplane.gif"
//     },
//     {
//       heading:"Manufacturing",
//       pera:"Svaak Software enhances manufacturing processes with innovative software solutions, optimizing efficiency and precision in every stage of production.",
//       icon:"/images/bracket/eco.gif"
//     }],
//     [{
//       heading:"Banking & Finance",
//       pera:"Svaak Software revolutionizes banking with advanced software solutions, optimizing operations and delivering a secure, seamless banking experience.",
//       icon:"/images/bracket/bank.gif"
//     },
//     {
//       heading:"E-Commerce",
//       pera:"Svaak Software powers e-commerce excellence with tailored solutions, delivering seamless online shopping experiences and driving business growth.",
//       icon:"/images/bracket/shopping-cart.gif"
//     }]
//   ]
// };



// const WelcomePage = () => {
//   const [modalIsOpen, setIsOpen] = useState(false);
//   function closeModal() {
//     setIsOpen(false);
//   }

//   return (
//     <>
//       <div className="Welcome_page_bannerDivPosition">
//         <div className="overlayBanner"></div>
//         <div className="home">
//           <video autoPlay muted loop className="bannerVideoPosition bg-white">
//             <source src="images/bannerVideo/bannerOne.mp4" type="video/mp4" />
//           </video>
//         </div>
//         <div className="hire-us-net">
//           <div className="overlap">
//             <div className="welcome_page_banner_text">
//               <div className="content">
//                 <div className="sub-content">
//                   <p className="elevate-your">
//                     <span className="text-wrapper">
//                       Elevate your business with
//                       {forMobileResponse > 769 ? <br /> : ""}
//                         <span> game-changing </span>
//                         {forMobileResponse < 769 ? <br /> : ""}
//                         <span className="text-[#21C3E8] random-word"></span> <br/>
//                       for maximum efficiency.
//                     </span>
//                   </p>
//                   <div>
//                       <Link to="/get-call" className="exploreNow_Css">Explore Now</Link>
//                   </div>
//                   <Modal
//                     isOpen={modalIsOpen}
//                     onRequestClose={closeModal}
//                     style={customStyles}
//                     contentLabel="Example Modal"
//                   >
//                     <button className="text-end text-3xl font-bold cursor-pointer bg-slate-200 rounded-lg ml-auto block">
//                       <RiCloseFill onClick={closeModal} />
//                     </button>
//                     <LetConnect closeModal={closeModal} />
//                   </Modal>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <span className="span">
//         <Industries
//           industryData={technologiesData}
//           industryTitle={technologiesTitle}
//         />
//         <Industries industryData={industryData} industryTitle={industryTitle} />

//         <IndustriesServed IndustriesServedData={IndustriesServedData} />
//         <WhyUsSolution whyUsSolutionData={whyUsSolutionData} />
//         <div className="border-y-2 mt-[40px] pb-20">
//           <Counter />
//         </div>
//         {/* <Blogs /> */}
//         <Contact peragraph={peragraph} />
//         {/* <Slider /> */}
//         <Certification />

//       </span>

//       <Helmet>
//         <script src="/customJs/headerdropdown.js" type="text/javascript" />
//         <script src="/customJs/randomWordsScript.js" type="text/javascript" />
//         <script src="/customJs/fixedHeader.js" type="text/javascript" />
//       </Helmet>
//     </>
//   );
// };

// export default WelcomePage;
