import {Helmet} from "react-helmet";

const Counter = () => {
 
  return (
    <>
      <div className="div-164">
      <span className="span-51">
        <div className="counter_number_start div-165">30+</div>
        <div className="div-166"></div>
        <div className="div-167">Delivered Projects</div>
      </span>
      <span className="span-52">
        <div className="counter_number_start div-168">20</div>
        <div className="div-169"></div>
        <div className="div-170">Satisfied Clients</div>
      </span>
    </div>
    <Helmet>
  <script src="/customJs/counterNumber.js" type="text/javascript" />
</Helmet>
    </>
  )
}

export default Counter
