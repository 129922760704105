import {baseUrl, sendNodemailerEmail} from "../Includes/BaseUrl";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { BsPerson } from "react-icons/bs";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { LuPencil } from "react-icons/lu";
import { IoIosArrowDropdown } from "react-icons/io";
import { Bounce, toast } from 'react-toastify';
import Contact from "../Components/Contact";
import {Helmet} from "react-helmet";


const peragraph = "Let's start a conversation. Contact Svaak today, and let's explore the possibilities together!"


const GetCall = () => {
  const {reset,register,handleSubmit,formState: { errors }} = useForm();
  const mobileForm = useForm();
  

  const onSubmit = async(contactDetails) =>{
    try {
     const {data} = await axios.post(`${baseUrl}/auth/contact`,contactDetails);
     reset({name: "",email: "", mobile: "",message: "",selectedType:""});
     toast.success(data.message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      });
      
    } catch (error) {
      toast.error(error?.error?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
    }
  }
  const onSubmitMobile = async(contactDetails) =>{
    try {
     const {data} = await axios.post(`${baseUrl}/auth/contact`,contactDetails);
     mobileForm.reset({name: "",email: "", mobile: "",message: "",selectedType:""});
     toast.success(data.message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      });
      
    } catch (error) {
      toast.error(error?.error?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
    }
  }

  return (
    <>
   <div className="getcallTop_padding">
   <Contact peragraph={peragraph} />
   </div>

      {/* this section is commented by ashiv because this is not prefect design */}
      {/* <div className="get_call_page flex flex-col justify-center items-center h-screen getCall-desktop">
        <div className="mb-4">
          <h1 className="text-5xl font-bold">
            <span className="get_call_email_color">Share details </span> and we will <span className="get_call_email_color"> Connect</span> Soon
          </h1>
          <p className="text-xl my-4 text-slate-500 get__call__pera">
            Feel free to reach out with questions; {`we're`} here to help and will
            respond promptly
          </p>
        </div>
        <div className="flex flex-wrap mt-5">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap justify-center gap-10" >
            <div className="flex flex-wrap justify-center gap-10">
              <div className="flex w-[34rem] border-2 p-4 text-xl getCallForm">
                <BsPerson className="text-3xl rounded-full" />
                <input type="text" placeholder=" Your Full Name" name="name" {...register("name", {
                  required: true
                 })} className="px-2 outline-0 w-full" /> 
              {errors.name && errors.name.type === "required" && (
            <p className="get_call_errorMsg">Name is required.</p>
            )}
              </div>
              <div className="flex w-[34rem] border-2 p-4 text-xl getCallForm">
                <LuPhoneCall className="text-3xl rounded-full" />
                <input type="number" placeholder="Your Contact Detail" name="mobile" {...register("mobile", {
                  required: false
                 })} className="px-2 outline-0 w-full "/>
              </div>
              <div className="flex gap-10">
                <div className="flex flex-col gap-10 flex-wrap">
                  <div className="flex w-[34rem] border-2 p-4 text-xl getCallForm">
                    <MdOutlineMail className="text-3xl rounded-full" />
                    <input type="text" placeholder="Your Email" name="email"
                       {...register("email", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                        })} className="px-2 outline-0 w-full" />
                      {errors.email && errors.email.type === "required" && (
                      <p className="get_call_errorMsg">Email is required.</p>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                      <p className="get_call_errorMsg">Email is not valid.</p>
                       )}
                  </div>
                  <div className="flex w-[34rem] border-2 p-4 text-xl getCallForm">
                    <IoIosArrowDropdown className="text-3xl rounded-full" />
                    <select type="text" placeholder="Message" name="selectedType" {...register("selectedType", {
                     required: false
                     })} className="px-2 outline-0 w-full text-gray-400">
                      <option value="I am an Individual"> I am an Individual </option>
                      <option value="For Business"> For Business </option>
                    </select>
                  </div>
                </div>
                <div className="flex w-[34rem] border-2 p-4 text-xl getCallForm">
                  <LuPencil className="text-3xl" />
                  <textarea type="text" rows="5" cols="50" placeholder="Message" name="message" {...register("message", {
                   required: true
                  })} className="px-2 outline-0 w-full" />
                  {errors.message && errors.message.type === "required" && (
                  <p className="get_call_message_errorMsg">Message is required.</p>
                   )}
                </div>
              </div>
            </div>
            <div>
            <button type="submit" className="exploreButtonCss">Submit</button>
          </div>
          </form>
        </div>
      </div>

      <div className="getCall-mobile get_call_form_page">
        <div className="flex flex-col justify-center items-center text-center">
          <h1 className="text-[2rem] font-bold ">
            <span className="get_call_email_color">Share details </span> and we will <span className="get_call_email_color"> Connect</span> Soon
          </h1>
          <p className="text-sm my-4 text-slate-500">
            Feel free to reach out with questions; {`we're`} here to help and will
            respond promptly.
          </p>
        </div>
        <form onSubmit={mobileForm.handleSubmit(onSubmitMobile)} className="flex flex-col gap-4 justify-center items-center">
          <div className="flex w-[90%] border-2 p-4 text-xl">
            <BsPerson className="text-3xl rounded-full" />
            <input type="text" placeholder="Your Full Name" name="name" {...mobileForm.register("name", {
                  required: true
                 })} className=" font-semibold px-2 outline-0 w-full"/>
                 {mobileForm.formState.errors.name && mobileForm.formState.errors.name.type === "required" && (
            <p className="get_call_errorMsg">Name is required.</p>
            )}
          </div>
          <div className="flex w-[90%] border-2 p-4 text-xl">
            <LuPhoneCall className="text-3xl rounded-full" />
            <input type="number" placeholder="Your Contact Detail" name="mobile" {...mobileForm.register("mobile", {
                  required: false
                 })} className=" font-semibold px-2 outline-0 w-full"/>
          </div>

          <div className="flex w-[90%] border-2 p-4 text-xl">
            <MdOutlineMail className="text-3xl rounded-full" />
            <input type="text" placeholder="Your Email" name="email"
                       {...mobileForm.register("email", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                        })} className=" font-semibold px-2 outline-0 w-full"/>
                {mobileForm.formState.errors.email && mobileForm.formState.errors.email.type === "required" && (
                  <p className="get_call_errorMsg">Email is required.</p>
                    )}
                 {mobileForm.formState.errors.email && mobileForm.formState.errors.email.type === "pattern" && (
                  <p className="get_call_errorMsg">Email is not valid.</p>
                   )}
          </div>
          <div className="flex w-[90%] border-2 p-4 text-xl">
            <IoIosArrowDropdown className="text-3xl rounded-full" />
            <select type="text" placeholder="Select" name="selectedType" {...mobileForm.register("selectedType", {
                     required: false
                     })} className="font-semibold px-2 outline-0 w-full" >
              <option value="I am an Individual"> I am an Individual </option>
              <option value="For Business"> For Business </option>
            </select>
          </div>
          <div className="flex w-[90%] border-2 p-4 text-xl">
            <LuPencil className="text-3xl" />
            <textarea
              type="text"
              rows="5"
              cols="50"
              placeholder="Message" name="message" {...mobileForm.register("message", {
                   required: true
                  })}
              className=" font-semibold px-2 outline-0 w-full"
            />
            {mobileForm.formState.errors.message && mobileForm.formState.errors.message.type === "required" && (
                  <p className="get_call_message_errorMsg">Message is required.</p>
                   )}
          </div>
          <div className="flex justify-center text-xl my-4">
              <button
                type="submit"
                className="w-24 h-14 rounded-md text-white font-bold bg-[#21C3E8]"
              >
                Submit
              </button>
            </div>
        </form>
      </div>

     
  <div className="flex justify-center items-center bg-[#F4F4F4] py-10">
      <div className="flex flex-wrap w-[95vw] justify-center gap-8 ">
        <div className="w-[500px] lg:h-[400px] bg-[#D9D9D9] translate-y-[-40px] sm:translate-x-[-430px] sm:translate-y-[-40px] absolute z-0"> </div>
        <div className="relative HireUs_ImageBg">
          <img src="/images/getcall/Background.png" alt="" className="w-[450px]  z-50" />
          <p className=" w-full h-[80px] bg-[rgba(17,28,84,0.68)] bottom-0 font-semibold text-white flex items-center justify-center image__hirehs">
            Hire Quality Potentials Globally
          </p>
        </div>
        <div className="w-[40%]  flex flex-col gap-3 office_address_pera_width">
          <p className="border-l-2 px-2 font-semibold border-[#1C1F35] office__address">
          Office Address
          </p>
          <h1 className="text-[#1C1F35] text-[1.7rem] font-bold office__title">
          Our Office Locations
          </h1>
          <p className="text-xs text-[#666C89] office_address_pera">
          Global Presence: SVAAC Offices in USA, India, Thailand, and Canada. Contact Us for Inquiries.
          </p>
          <div className="flex flex-col justify-center">
           
           <div className="flex my-3 gap-3">
           <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/53144a3abdaaf190adc915f4e1a4412273582aca6e360318f71f95e0a54563e3?" alt="" className="w-10 h-10" />
           <div>
           <span className="web-developers country_fontSize">India</span>
                <p className="get_call_addressname">Mr. Vishnu Sharma, Founder, MD</p>
                <p className="get_call_email_color">Email: vishnu@svaaksoftware.com </p>
                <p className="get_call_addressname">Address: A-140, A Block, Sector 63, Noida, Uttar Pradesh, India, 201301 <br /> Hand Phone: + 91 98182 09683</p>
           </div>
           </div>
           <div className="flex my-3 gap-3">
           <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/53144a3abdaaf190adc915f4e1a4412273582aca6e360318f71f95e0a54563e3?" alt="" className="w-10 h-10" />
           <div>
           <span className="web-developers country_fontSize">USA</span>
                <p className="get_call_addressname">Mrs. Sunita Sharma, Business Development Manager</p>
                <p className="get_call_email_color">Email:sunita@svaaksoftware.com</p>
                <p className="get_call_addressname">Phone: +12065366202<br /> Chicago Illinois.</p>
           </div>
           </div>
           <div className="flex my-3 gap-3">
           <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/53144a3abdaaf190adc915f4e1a4412273582aca6e360318f71f95e0a54563e3?" alt="" className="w-10 h-10" />
           <div>
           <span className="web-developers country_fontSize">Canada</span>
                <p className="get_call_addressname">Mrs. Richa, Business Development</p>
                <p className="get_call_email_color">Email: richa@svaaksoftware.com</p>
                <p className="get_call_addressname">Hand Phone: +4169496491<br /> Address:- Tumbleweed Trail, Brampton, Ontario L6Y4Z8</p>
           </div>
           </div>
           <div className="flex my-3 gap-3">
           <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/53144a3abdaaf190adc915f4e1a4412273582aca6e360318f71f95e0a54563e3?" alt="" className="w-10 h-10" />
           <div>
           <span className="web-developers country_fontSize">Thailand</span>
                <p className="get_call_addressname">Miss Matsorn Kitbumrung - Business Development Head</p>
                <p className="get_call_email_color">Email:matsorn@svaaksoftware.com </p>
                <p className="get_call_addressname">Hand Phone: +6681 497 1554<br /> Address: 1727 Lasalle Rd., Bangna Tai, Bangna, Bangkok, Thailand 10260</p>
           </div>
           </div>
          </div>
          <div className="flex justify-center">
            <button className="exploreButtonCss"> Explore Now </button>
          </div>
        </div>
      </div>
    </div> */}

    <Helmet>
    <script src="/customJs/fixedHeader.js" type="text/javascript" />
    <script src="/customJs/headerdropdown.js" type="text/javascript" />
    </Helmet>
    </>
  );
};

export default GetCall;

