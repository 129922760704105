/* eslint-disable react/prop-types */
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { useMemo } from "react";

const Industries = ({ industryData, industryTitle }) => {
  const { title1, title2 } = industryTitle;

  const renderSwiperSlides = useMemo(() => {
    return industryData.map((item) => (
      <SwiperSlide key={item.id}>
        {item.link ? (
          <a href={item.link} target="_blank" rel="noreferrer" onClick={() => localStorage.setItem('website_design', JSON.stringify(item))}>
            <div className={`${title1 === "Technologies" ? "" : "industry-slide-div"}`}>
              <div className={`${title1 === "Technologies" ? "" : "industry-slide-div2"}`}>
                <img src={item.image} alt="img" />
                <p className={`${title1 === "Technologies" ? "technology_logo__title" : ""}`}>
                  {item.para} <br /> <span className="fontsizeSpan_tag"> {item.para2} </span>
                </p>
              </div>
            </div>
          </a>
        ) : (
          <Link to={item.para2} onClick={() => localStorage.setItem('website_design', JSON.stringify(item))}>
            <div className={`${title1 === "Technologies" ? "" : "industry-slide-div"}`}>
              <div className={`${title1 === "Technologies" ? "" : "industry-slide-div2"}`}>
                <img src={item.image} alt="img" style={{}} />
                <p className={`${title1 === "Technologies" ? "technology_logo__title" : ""}`}>
                  {item.para} <br /> <span className="fontsizeSpan_tag"> {item.para2} </span>
                </p>
              </div>
            </div>
          </Link>
        )}
      </SwiperSlide>
    ));
  }, [industryData, title1]);

  return (
    <div className="industry-div">
      <div className="flex flex-col items-center gap-12 ">
        <div>
          <h2 className="text-[3rem] font-semibold text-center headingFontSize">
            <span style={{ color: title2 !== "Leadership Team" ? "#21C3E8" : "" }}>{title1}</span> <span style={{ color: title2 === "Leadership Team" ? "#21C3E8" : "" }}>{title2}</span>
          </h2>
        </div>

        <div className={`${title1 === "Technologies" ? "w-[80vw] flex justify-center items-center technology__images_box_padding" : (title1 === "SAAS") ? "w-[60vw] flex justify-center items-center" : "w-[91vw] flex justify-center items-center"}`}>
          <Swiper
            slidesPerView={1}
            breakpoints={{
              120: { slidesPerView: title1 === "Technologies" ? 3 : 2 },
              481: { slidesPerView: title1 === "Technologies" ? 4 : 3 },
              769: { slidesPerView: title1 === "Technologies" ? 5 : 4 },
              1025: { slidesPerView: title1 === "Technologies" ? 6 : (title1 === "SAAS") ? 3 : 5 }
            }}
            spaceBetween={10}
            loop={true}
            autoplay={{ delay: 1500 }}
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            {renderSwiperSlides}
          </Swiper>
        </div>
        <div>
          {title1 === "Technologies" ? <button className="exploreButtonCss"> Discover more</button> : ""}
        </div>
      </div>
    </div>
  );
};

export default Industries;







// import React from 'react'

// const Industries = ({pic}) => {
//   return (
//     <>
//        <div className="div-111">
//     <span style={{color: "rgba(22, 139, 242, 1)"}}>Industries <span className='ServedColor'>Served</span> </span>
//   </div>
//   <div className="div-112">
//     <div className="div-113">
//       <img
//         loading="lazy"
//         src={pic[0]}
//         className="img"
//       />
//       <div className="div-114">Banking & Finances</div>
//       <div className="div-115">CMS Development</div>
//     </div>
//     <div className="div-116">
//       <img
//         loading="lazy"
//         src={pic[1]}
//         className="img"
//       />
//       <div className="div-117">Hospitality & Tourism</div>
//       <div className="div-118">SAAS</div>
//     </div>
//     <div className="div-119">
//       <img
//         loading="lazy"
//         src={pic[2]}
//         className="img"
//       />
//       <div className="div-120">Transport & Logistics</div>
//       <div className="div-121">Road Transportation App</div>
//     </div>
//     <div className="div-122">
//       <img
//         loading="lazy"
//         src={pic[3]}
//         className="img"
//       />
//       <div className="div-123">Manufacturing</div>
//       <div className="div-124">Website</div>
//     </div>
//     <div className="div-125">
//       <img
//         loading="lazy"
//         src={pic[4]}
//         className="img"
//       />
//       <div className="div-126">E Learning</div>
//       <div className="div-127">Mobile Application</div>
//     </div>
//   </div>
//   <div className='buttonCenterCls'>
//   <button className="button-b0">
//       <span className="more-blogs">Explore Now</span>
//     </button>
//   </div>
//     </>
//   )
// }

// export default Industries
