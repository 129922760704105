import React from "react";
import {baseUrl} from "../Includes/BaseUrl";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { BsPerson } from "react-icons/bs";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { LuPencil } from "react-icons/lu";
import { IoIosArrowDropdown } from "react-icons/io";
import { Bounce, toast } from 'react-toastify';

const LetConnect = ({closeModal}) => {
  const {reset,register,handleSubmit,formState: { errors }} = useForm();
  const mobileForm = useForm();
  

  const onSubmit = async(contactDetails) =>{
    try {
     const {data} = await axios.post(`${baseUrl}/auth/contact`,contactDetails);
     reset({name: "",email: "", mobile: "",message: "",selectedType:""});
     closeModal();
     toast.success(data.message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      });
      
    } catch (error) {
      toast.error(error?.error?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
    }
  }
  const onSubmitMobile = async(contactDetails) =>{
    try {
     const {data} = await axios.post(`${baseUrl}/auth/contact`,contactDetails);
     mobileForm.reset({name: "",email: "", mobile: "",message: "",selectedType:""});
     closeModal();
     toast.success(data.message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      });
      
    } catch (error) {
      toast.error(error?.error?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
    }
  }


  return (
    <>
      <div className="getCall-desktop flex flex-col justify-center items-center h-screen overflow-scroll">
        <div>
          <h1 className="text-5xl font-bold">
            <span >Share details </span> and we will <span > Connect</span> Soon
          </h1>
          <p className="text-xl my-4 text-slate-500">
            Feel free to reach out with questions; we're here to help and will
            respond promptly
          </p>
        </div>
        <div className="flex flex-wrap">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap justify-center gap-10" >
            <div className="flex flex-wrap justify-center gap-10">
              <div className="flex w-[34rem] border-2 p-4 text-xl">
                <BsPerson className="text-3xl rounded-full" />
                <input type="text" placeholder=" Your Full Name" name="name" {...register("name", {
                  required: true
                 })} className="px-2 outline-0 w-full" /> 
              {errors.name && errors.name.type === "required" && (
            <p className="get_call_errorMsg">Name is required.</p>
            )}
              </div>
              <div className="flex w-[34rem] border-2 p-4 text-xl">
                <LuPhoneCall className="text-3xl rounded-full" />
                <input type="number" placeholder="Your Contact Detail" name="mobile" {...register("mobile", {
                  required: false
                 })} className="px-2 outline-0 w-full"/>
              </div>
              <div className="flex gap-10">
                <div className="flex flex-col gap-10 flex-wrap">
                  <div className="flex w-[34rem] border-2 p-4 text-xl">
                    <MdOutlineMail className="text-3xl rounded-full" />
                    <input type="text" placeholder="Your Email" name="email"
                       {...register("email", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                        })} className="px-2 outline-0 w-full" />
                      {errors.email && errors.email.type === "required" && (
                      <p className="get_call_errorMsg">Email is required.</p>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                      <p className="get_call_errorMsg">Email is not valid.</p>
                       )}
                  </div>
                  <div className="flex w-[34rem] border-2 p-4 text-xl">
                    <IoIosArrowDropdown className="text-3xl rounded-full" />
                    <select type="text" placeholder="Message" name="selectedType" {...register("selectedType", {
                     required: false
                     })} className=" outline-0 px-2 text-gray-400">
                      <option value="I am an Individual"> I am an Individual </option>
                      <option value="For Business"> For Business </option>
                    </select>
                  </div>
                </div>
                <div className="flex w-[34rem] border-2 p-4 text-xl">
                  <LuPencil className="text-3xl" />
                  <textarea type="text" rows="5" cols="50" placeholder="Message" name="message" {...register("message", {
                   required: true
                  })} className="px-2 outline-0 w-full" />
                  {errors.message && errors.message.type === "required" && (
                  <p className="get_call_message_errorMsg">Message is required.</p>
                   )}
                </div>
              </div>
            </div>
            <div>
            <button type="submit" className="exploreButtonCss">Submit</button>
          </div>
          </form>
        </div>
      </div>

      <div className="getCall-mobile">
        <div className="flex flex-col justify-center items-center text-center">
          <h1 className="text-[2rem] font-bold ">
            <span >Share details </span> and we will <span > Connect</span> Soon
          </h1>
          <p className="text-sm my-4 text-slate-500">
            Feel free to reach out with questions; we're here to help and will
            respond promptly
          </p>
        </div>
        <form onSubmit={mobileForm.handleSubmit(onSubmitMobile)} className="flex flex-col gap-4 justify-center items-center">
          <div className="flex w-[90%] border-2 p-4 text-xl">
            <BsPerson className="text-3xl rounded-full" />
            <input type="text" placeholder="Your Full Name" name="name" {...mobileForm.register("name", {
                  required: true
                 })} className=" font-semibold px-2 outline-0 w-full"/>
                 {mobileForm.formState.errors.name && mobileForm.formState.errors.name.type === "required" && (
            <p className="get_call_errorMsg">Name is required.</p>
            )}
          </div>
          <div className="flex w-[90%] border-2 p-4 text-xl">
            <LuPhoneCall className="text-3xl rounded-full" />
            <input type="number" placeholder="Your Contact Detail" name="mobile" {...mobileForm.register("mobile", {
                  required: false
                 })} className=" font-semibold px-2 outline-0 w-full"/>
          </div>

          <div className="flex w-[90%] border-2 p-4 text-xl">
            <MdOutlineMail className="text-3xl rounded-full" />
            <input type="text" placeholder="Your Email" name="email"
                       {...mobileForm.register("email", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                        })} className=" font-semibold px-2 outline-0 w-full"/>
                {mobileForm.formState.errors.email && mobileForm.formState.errors.email.type === "required" && (
                  <p className="get_call_errorMsg">Email is required.</p>
                    )}
                 {mobileForm.formState.errors.email && mobileForm.formState.errors.email.type === "pattern" && (
                  <p className="get_call_errorMsg">Email is not valid.</p>
                   )}
          </div>
          <div className="flex w-[90%] border-2 p-4 text-xl">
            <IoIosArrowDropdown className="text-3xl rounded-full" />
            <select type="text" placeholder="Select" name="selectedType" {...mobileForm.register("selectedType", {
                     required: false
                     })} className="font-semibold px-2 outline-0 w-full" >
              <option value="I am an Individual"> I am an Individual </option>
              <option value="For Business"> For Business </option>
            </select>
          </div>
          <div className="flex w-[90%] border-2 p-4 text-xl">
            <LuPencil className="text-3xl" />
            <textarea
              type="text"
              rows="5"
              cols="50"
              placeholder="Message" name="message" {...mobileForm.register("message", {
                   required: true
                  })}
              className=" font-semibold px-2 outline-0 w-full"
            />
            {mobileForm.formState.errors.message && mobileForm.formState.errors.message.type === "required" && (
                  <p className="get_call_message_errorMsg">Message is required.</p>
                   )}
          </div>
          <div className="flex justify-center text-xl my-4">
              <button
                type="submit"
                className="w-24 h-14 rounded-md text-white font-bold bg-[#21C3E8]"
              >
                Submit
              </button>
            </div>
        </form>
      </div>
    </>
  );
};

export default LetConnect;

