import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

const UnderConstruction = () => {

  return (
    <>
      <div className="underConstruction_section">
        <div className="underConstructor_center">
          this page is <span></span>
          <span style={{ fontWeight: "700" }}>Under</span>
          <span style={{ fontWeight: "700", color: "rgba(33, 195, 232, 1)" }}>
            Construction
          </span>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b7c20e25ddc867e616dcee54582ac8d3061ee2b06649db1e0354f5e2c0f21bd2?"
          className="underConstruction_img"
        />
        <Link to="/" className="goback_btn_under_construction">
          <div className="underConstruction_goback">Go Back</div>
        </Link>
      </div>
      <Helmet>
        <script src="/customJs/fixedHeader.js" type="text/javascript" />
        <script src="/customJs/headerdropdown.js" type="text/javascript" />
      </Helmet>
    </>
  );
}

export default UnderConstruction
