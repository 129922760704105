import "./CssFiles/Header.css";
import "./CssFiles/Welcome.css";
import "./CssFiles/HireUs.css";
import 'react-toastify/dist/ReactToastify.css';
import { useEffect,lazy,Suspense } from "react";
import { Routes, Route, useLocation,HashRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Header from "./Includes/Header";
import Footer from "./Includes/Footer";
import WelcomePage from "./Pages/WelcomePage";
import GetCall from "./Pages/GetCall";
import UnderConstruction from "./Includes/UnderConstruction";
import Errorpage from "./Includes/Errorpage";
import Loader from "./Includes/Loader";



const Technology = lazy(() => import("./Pages/technology/Technology"));
const DotNet = lazy(() => import("./Pages/HireUs/DotNet"));
const AboutUs = lazy(() => import("./Pages/Insigths/AboutUs"));
const UxDesign = lazy(() => import("./Pages/Services/UxDesign"));
const WebDevelopment = lazy(() => import("./Pages/Services/WebDevelopment"));
const MobileApplication = lazy(() => import("./Pages/Services/MobileApplication"));
const DigitalMarketing = lazy(() => import("./Pages/Services/DigitalMarketing"));
const CloudDevops = lazy(() => import("./Pages/Services/CloudDevops"));
const Salesforce = lazy(() => import("./Pages/Services/Salesforce"));
const Iot = lazy(() => import("./Pages/Services/Iot"));
const Analytics = lazy(() => import("./Pages/Services/Analytics"));
const ArtificialIntelligence = lazy(() => import("./Pages/Services/ArtificialIntelligence"));
const Blockchain = lazy(() => import("./Pages/Services/Blockchain"));
const GamesDevelopment = lazy(() => import("./Pages/Services/GamesDevelopment"));
const Animations = lazy(() => import("./Pages/Services/Animations"));
const MixedReality = lazy(() => import("./Pages/Services/MixedReality"));
const BusinessAnalysis = lazy(() => import("./Pages/Services/BusinessAnalysis"));
const QaTesting = lazy(() => import("./Pages/Services/QaTesting"));
const CyberSecurity = lazy(() => import("./Pages/Services/CyberSecurity"));
const SupportMaintenance = lazy(() => import("./Pages/Services/SupportMaintenance"));
const ProjectManagement = lazy(() => import("./Pages/Services/ProjectManagement"));
const HireUs = lazy(() => import("./Pages/Services/HireUs"));
const DevelopmentPricings = lazy(() => import("./Pages/Services/DevelopmentPricings"));
const Portfolio = lazy(() => import("./Pages/portfolio/Portfolio"));
const Banking = lazy(() => import("./Pages/Industries/Banking"));
const Healthcare = lazy(() => import("./Pages/Industries/Healthcare"));
const Telecome = lazy(() => import("./Pages/Industries/Telecome"));
const Hospitality = lazy(() => import("./Pages/Industries/Hospitality"));
const Tourism = lazy(() => import("./Pages/Industries/Tourism"));
const Ecommerce = lazy(() => import("./Pages/Industries/Ecommerce"));
const Elearning = lazy(() => import("./Pages/Industries/Elearning"));
const Transportation = lazy(() => import("./Pages/Industries/Transportation"));
const Manufacturing = lazy(() => import("./Pages/Industries/Manufacturing"));
const PortfolioPage = lazy(() => import("./Components/PortfolioPage"));

function App() {
  
  return (
    <>
      <HashRouter>
      <ScrollToTop />
      <ToastContainer />
        <Header />
      <Suspense fallback={<Loader />} >
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/technologies" element={<Technology />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:pageId"  element={<PortfolioPage />} />
          <Route path="/pricing_plans/hire_us" element={<HireUs />} />
          <Route path="/pricing_plans/development_pricings" element={<DevelopmentPricings />} />
          <Route path="/hire-us/dotnet-developer" element={<DotNet />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/get-call" element={<GetCall />} />
          <Route path="/services/blockchain" element={<Blockchain />} />
          <Route path="/services/ux_ui_design" element={<UxDesign />} />
          <Route path="/services/web_development" element={<WebDevelopment />} />
          <Route path="/services/mobile_application" element={<MobileApplication />} />
          <Route path="/services/digital_marketing" element={<DigitalMarketing />} />
          <Route path="/services/cloud_devops" element={<CloudDevops />} />
          <Route path="/services/salesforce" element={<Salesforce />} />
          <Route path="/services/iot" element={<Iot />} />
          <Route path="/services/data_analytics" element={<Analytics />} />
          <Route path="/services/Artificial_Intelligence" element={<ArtificialIntelligence />} />
          <Route path="/services/blockchain" element={<Blockchain />} />
          <Route path="/services/games_development" element={<GamesDevelopment />} />
          <Route path="/services/animations" element={<Animations />} />
          <Route path="/services/mixedReality_ar_&_vr" element={<MixedReality />} />
          <Route path="/services/business_analysis" element={<BusinessAnalysis />} />
          <Route path="/services/qa_software_testing" element={<QaTesting />} />
          <Route path="/services/cyber_security" element={<CyberSecurity />} />
          <Route path="/services/support_maintenance" element={<SupportMaintenance />} />
          <Route path="/services/project_management" element={<ProjectManagement />} />
          <Route path="/industries/banking" element={<Banking />} />
          <Route path="/industries/healthcare" element={<Healthcare />} />
          <Route path="/industries/telecome" element={<Telecome />} />
          <Route path="/industries/hospitality" element={<Hospitality />} />
          <Route path="/industries/tourism" element={<Tourism />} />
          <Route path="/industries/ecommerce" element={<Ecommerce />} />
          <Route path="/industries/elearning" element={<Elearning />} />
          <Route path="/industries/transportation_&_Logistics" element={<Transportation />} />
          <Route path="/industries/manufacturing" element={<Manufacturing />} />
          <Route path="/under_construction" element={<UnderConstruction />} />
          <Route path="*" element={<Errorpage />} />
        </Routes>
        </Suspense>
        <Footer />
      </HashRouter>
    </>
  );
}

export default App;


 function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [pathname]);

  return null;
}


      {/* <marquee style={{color:"#ffff",fontSize:"2rem", backgroundColor:"#21c3e8",position:"fixed",left:0,bottom:"8px",zIndex:"999999999"}}>This site is Under Construction  </marquee> */}