import React from 'react';



const IndustriesServed = ({IndustriesServedData}) => {
  const {title,image1,image2,lists} = IndustriesServedData;
  return (
    <>
       <div className="div-37">
        <div className="div-38">
          <div className="column-5">
            <span className="span-19">
              <div className="div-39">
                <span className="span-45 border-l-4 border-black"> What We Do</span>
              </div>
              <div className="div-41">{title}</div>
              <div className="industries_first_col">
                <div className="industries_first_col_div">
                  <img
                    loading="lazy"
                    src={image1}
                    className="img"
                  />
                  <img
                    loading="lazy"
                    src={image2}
                    className="container_image"
                  />
                </div>
              </div>
            </span>
          </div>
          <div className="column-6">
            <div className="div-44">
              <div className="div-45">
               {lists.map((listsItem,index)=> (
                <div className="div-46" key={index}>
                  {listsItem.map(({heading,pera,icon},index)=> (
                    <div className="column-7" key={index}>
                    <div className="div-47">
                      {icon&&<img
                        loading="lazy"
                        src={icon}
                        className="img-14"
                      />}
                      {icon&&<div className="div-48"></div>}
                      <span className="span-22">
                        <div className="div-49">{heading}</div>
                        <div className="div-50">{pera}</div>
                      </span>
                    </div>
                  </div>
                  ))}
                </div>
               ))}
              </div>
            </div>
          </div>
        </div>
    </div>
    </>
  )
}

export default IndustriesServed












//   for removing css anywhere                <div className="column-8">
//                     <div className="div-51">
//                       <img
//                         loading="lazy"
//                         src="/images/bracket/online-degree.gif"
//                         className="img-15"
//                       />
//                       <div className="div-52"></div>
//                       <span className="span-23">
//                         <div className="div-53">E-Learning</div>
//                         <div className="div-54">
//                         Svaak Software transforms education with dynamic e-learning solutions, fostering interactive and engaging digital learning experiences.
//                         </div>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="div-55">
//                 <div className="div-56">
//                   <div className="column-9">
//                     <div className="div-57">
//                       <img
//                         loading="lazy"
//                         src="/images/bracket/hotel-sign.gif"
//                         className="img-16"
//                       />
//                       <div className="div-58"></div>
//                       <span className="span-24">
//                         <div className="div-59">Hospitality</div>
//                         <div className="div-60">
//                         Svaak Software elevates hospitality with cutting-edge technology solutions, enhancing guest experiences and operational efficiency.
//                         </div>
//                       </span>
//                     </div>
//                   </div>
//                   <div className="column-10">
//                     <div className="div-61">
//                       <img
//                         loading="lazy"
//                         src="/images/bracket/truck.gif"
//                         className="img-17"
//                       />
//                       <div className="div-62"></div>
//                       <span className="span-25">
//                         <div className="div-63">
//                         Transportation & Logistic
//                         </div>
//                         <div className="div-64">
//                         Svaak Software revolutionizes transport and logistics with tailored software and web development solutions, optimizing operations for efficiency and precision.
//                         </div>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="div-65">
//                 <div className="div-66">
//                   <div className="column-11">
//                     <div className="div-67">
//                       <img
//                         loading="lazy"
//                         src="/images/bracket/airplane.gif"
//                         className="img-18"
//                       />
//                       <div className="div-68"></div>
//                       <span className="span-26">
//                         <div className="div-69">Tourism</div>
//                         <div className="div-70">
//                         Svaak Software redefines tourism with innovative technology solutions, shaping seamless experiences for travellers worldwide.
//                         </div>
//                       </span>
//                     </div>
//                   </div>
//                   <div className="column-12">
//                     <div className="div-71">
//                       <img
//                         loading="lazy"
//                         src="/images/bracket/eco.gif"
//                         className="img-19"
//                       />
//                       <div className="div-72"></div>
//                       <span className="span-27">
//                         <div className="div-73">Manufacturing</div>
//                         <div className="div-74">
//                         Svaak Software enhances manufacturing processes with innovative software solutions, optimizing efficiency and precision in every stage of production.
//                         </div>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="div-75">
//                 <div className="div-76">
//                   <div className="column-13">
//                     <div className="div-77">
//                       <img
//                         loading="lazy"
//                         src="/images/bracket/bank.gif"
//                         className="img-20"
//                       />
//                       <div className="div-78"></div>
//                       <span className="span-28">
//                         <div className="div-79">Banking & Finance</div>
//                         <div className="div-80">
//                         Svaak Software revolutionizes banking with advanced software solutions, optimizing operations and delivering a secure, seamless banking experience.
//                         </div>
//                       </span>
//                     </div>
//                   </div>
//                   <div className="column-14">
//                     <div className="div-81">
//                       <img
//                         loading="lazy"
//                         src="/images/bracket/shopping-cart.gif"
//                         className="img-21"
//                       />
//                       <div className="div-82"></div>
//                       <span className="span-29">
//                         <div className="div-83">E-Commerce</div>
//                         <div className="div-84">
//                         Svaak Software powers e-commerce excellence with tailored solutions, delivering seamless online shopping experiences and driving business growth.
//                         </div>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//     </div>
//     </>
//   )
// }

// export default IndustriesServed
