import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const forMobileResponse = window.screen.width;

  const [showPages, setShowPages] = useState(false);
  const [showUtility, setShowUtility] = useState(false);
  const [showColumn40, setShowColumn40] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setShowColumn40(false);
    } else {
      setShowColumn40(true);
    }
  }, [windowWidth]);

  return (
    <>
      <div className="div-216">
        <div className="div-217">
          <img loading="lazy" src="/images/footer.webp" className="img" />
          <div className="div-218">
            <div className="div-219">
              <div className="div-220">
                <div className="column-39">
                  <span className="span-71">
                    <Link to="/">
                      <span className="" style={{ display: "flex" }}>
                        <img
                          loading="lazy"
                          src="/images/svaakLogo.png"
                          className="img-49 header-mainDiv-img"
                        />
                        <div className="footer-logo flex text-[1.5rem] text-white font-semibold">
                          <p className="pr-2 text-[#21C3E8]"> Svaak </p>
                          <p className="text-white flex">
                            S <span className="text-[#21C3E8]"> o </span>ftware
                          </p>  
                        </div>
                      </span>
                    </Link>

                    <div className="div-222">
                      Leverage agile frameworks to provide a robust synopsis for
                      strategy collaborative thinking to further the overall
                      value proposition.
                    </div>
                    {forMobileResponse <  769 ?  
                    <span className="h-40 py-6 footerAdress">
                    <address className="pt-5 flex text-white">
                      <div>
                        <strong className="text-[2.5rem] px-2">Development Centre</strong>
                        <div className="flex px-2">
                          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/53144a3abdaaf190adc915f4e1a4412273582aca6e360318f71f95e0a54563e3?" className="img-52" />
                          <p className="px-4 div-224 py-4"> A-140, A Block, Sector 63,<br /> Noida, Uttar Pradesh, <br /> India,
                          201301</p>
                        </div>
                      </div>
                      </address>
                    </span> : ""}
                    <span className="span-73">
                      
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/94928614eba47f84e06592304e9f101a562a2dd67e0aec38f85f047fcd892f2e?"
                        className="img-50"
                      />

                      <div className="div-223">
                        Email :
                        <br />
                        info@svaaksoftware.com
                      </div>
                    </span>
                    <span className="span-74">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a16cfd0c24af35d414739720fd0004f198246ef57cdc8f746b65f4ef6f31d2c?"
                        className="img-51"
                      />
                      <div className="div-224">
                        <a href={`tel:${"+91 9818209683"}`}>
                          Call Us
                        <br />
                        Hand Phone :
                        <br />+ 91 98182 09683</a>
                      </div>
                    </span>
                  </span>
                </div>
                {!showColumn40 && (
                  <div className="column-40">
                    <div className="div-228">
                      <div className="div-229">
                        {/* <div className="column-41">
                          <h3>Web Development Utility</h3>
                        </div>
                        <div className="column-42">
                        <h3>Subscribe</h3>
                        </div> */}
                      </div>
                      <div className="div-230">
                        <div className="div-231">
                          <div className="column-41">
                            <span className="span-78">
                              <div
                                className="div-232"
                                onClick={() => setShowPages(!showPages)}
                              >
                                Pages
                              </div>
                              {showPages && (
                                <div className="div-233">
                                  <span>
                                    <Link to={"/technologies"}>
                                      Technologies
                                    </Link>
                                  </span>
                                  <br />
                                  <span>Services</span>
                                  <br />
                                  <span>Industries</span>
                                  <br />
                                  <span>
                                    <Link to="/about-us">About Us </Link>
                                  </span>
                                  <br />
                                  <span>Blogs</span>
                                </div>
                              )}
                            </span>
                          </div>
                          <div className="column-42">
                            <div className="div-234">
                              <div className="div-235">
                                <div className="column-43">
                                  <span className="span-79">
                                    <div
                                      className="div-236"
                                      onClick={() =>
                                        setShowUtility(!showUtility)
                                      }
                                    >
                                      Utility
                                    </div>
                                    {showUtility && (
                                      <div className="div-237">
                                        Terms & Conditions
                                        <br />
                                        Privacy Policies
                                        <br />
                                        Certificates
                                      </div>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="column-46">
                            <span className="span-82">
                              <div className="div-242">Subscribe </div>
                              <input
                                type="email"
                                placeholder="info@svaaksoftware.com"
                                className="span-83"
                              />
                              <div className="div-243">
                                <span className="span-84">Send Now</span>
                                <a
                                  href="https://www.linkedin.com/company/svaak-software-technology-pvt-ltd/mycompany/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/90fbf5eca21a68b808de34ab379b2d7d466056d415427113e54afece96e4a48d?"
                                    className="img-55"
                                  />
                                </a>
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ff7d8cb3ac1a7e80f57f9eba63b76fe88bc19a3801abb53c1d5455a27a54bbc?"
                                  className="img-57"
                                />
                                <a
                                  href="https://www.facebook.com/SVAAKSoftware/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/6d3e1a9699bb5a880d0110e1695ff620c4cafa734e1158d5587af4498c5b5208?"
                                    className="img-58"
                                  />
                                </a>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showColumn40 && (
                  <div className="column-40">
                    <div className="div-228">
                      <div className="div-229">
                        <div className="column-41">
                          <h3>Pages</h3>
                        </div>
                        <div className="column-41">
                          <h3>Utility</h3>
                        </div>
                        <div className="column-41">
                          <h3>Subscribe</h3>
                        </div>
                        <div className="footer_pages_single_line">
                          <h3>Our Office Location</h3>
                        </div>
                      </div>
                      <div className="div-230">
                        <div className="div-231">
                          <div className="column-41">
                            <span className="span-78">
                              {/* <div className="div-232">Pages </div> */}
                              <div className="div-233">
                                <p className="Pages__titles">
                                  <Link to={"/technologies"}>
                                    {" "}
                                    Technologies{" "}
                                  </Link>
                                </p>
                                <p className="Pages__titles">Services</p>
                                <p className="Pages__titles">Industries</p>
                                <p className="Pages__titles">
                                  <Link to="/about-us">About Us</Link>
                                </p>
                                <p className="Pages__titles">Blogs</p>
                              </div>
                            </span>
                          </div>
                          <div className="column-42">
                            <div className="div-234">
                              <div className="div-235">
                                <span className="span-79">
                                  {/* <div className="div-236">Utility</div> */}
                                  <div className="div-237">
                                    <p className="Pages__titles">
                                      {" "}
                                      Terms & Conditions
                                    </p>
                                    <p className="Pages__titles">
                                      Privacy Policies
                                    </p>
                                    <p className="Pages__titles">
                                      Certificates
                                    </p>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="column-46">
                            <span className="span-82">
                              <input
                                type="email"
                                placeholder="info@svaaksoftware.com"
                                className="span-83"
                              />
                              <div className="div-243">
                                <span className="span-84">Send Now</span>
                                <a
                                  href="https://www.linkedin.com/company/svaak-software-technology-pvt-ltd/mycompany/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/90fbf5eca21a68b808de34ab379b2d7d466056d415427113e54afece96e4a48d?"
                                    className="img-55"
                                  />
                                </a>
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ff7d8cb3ac1a7e80f57f9eba63b76fe88bc19a3801abb53c1d5455a27a54bbc?"
                                  className="img-57"
                                />
                                <a
                                  href="https://www.facebook.com/SVAAKSoftware/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/6d3e1a9699bb5a880d0110e1695ff620c4cafa734e1158d5587af4498c5b5208?"
                                    className="img-58"
                                  />
                                </a>
                              </div>
                            </span>
                          </div>
                          <div className="column-46">
                            <span className="span-82">
                              <span className=" footer__location_point">
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/53144a3abdaaf190adc915f4e1a4412273582aca6e360318f71f95e0a54563e3?"
                                  className="img-52"
                                />
                                <div className="div-225">
                                  <span className="office_location_footer">
                                    Development Centre
                                  </span>
                                  <br /> Hand Phone: <br /> + 91 98182 09683{" "}
                                  <br />
                                  A-140, A Block, Sector 63, <br /> Noida, Uttar
                                  Pradesh,
                                  <br /> India, 201301
                                </div>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="div-244"></div>
            <span className="span-85">
              <div className="div-245">
                Copyright © Svaak Software Technologies PVT LTD
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
