import React, { useRef } from "react";
import { sendNodemailerEmail } from "../Includes/BaseUrl"; // assuming baseUrl is not needed here
import { useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";

const Contact = () => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const form = useRef();

  const onSubmit = async (contactDetails) => {
    // Check if any required fields are empty
    if (!contactDetails.user_name || !contactDetails.user_email || !contactDetails.message) {
      toast.error("All fields are mandatory", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      return; // Exit the function
    }

    try {
      const email = await sendNodemailerEmail(form.current);
      if (email) {
        reset({ user_name: "", user_email: "", user_mobile: "", message: "" });
      }
      toast.success("email send successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    } catch (error) {
      toast.error(error.message || "An error occurred", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  return (
    <div className="div-188 bg-[#F5F5F5] py-10">
      <div className="div-189">
        <div className="div-190">
          <div className="column-37">
            <span className="span-59">
              <div className="div-154" style={{ background: "#E8E8E8" }}>
                <div className="div-155"></div>
                <span className="span-45">Contact</span>
              </div>
              <div className="div-193">Let’s Connect</div>
              <span className="span-61">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9b2ba696642de5becafc1f54eaa5bac3dcf5c7330b5a4bb5770142028d622fb0?"
                  className="img-44"
                  alt="email"
                />
                <div className="div-195">
                <a href={`mailto:info@svaaksoftware.com`}>
                  Email
                  <br />
                  info@svaaksoftware.com</a>
                </div>
              </span>
              <span className="span-62">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4342caba411354c5a35a004cebbea9eff047abd4d9eb0308f47762d61319b55c?"
                  className="img-45"
                  alt="phone"
                />
                <a href={`tel:${"+91 9818209683"}`}>
                  <div className="div-196">
                  Call Us
                  <br />+ 91 98182 09683
                </div></a>
              </span>
              <span className="span-63">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/53144a3abdaaf190adc915f4e1a4412273582aca6e360318f71f95e0a54563e3?"
                  className="img-46"
                  alt="location"
                />
                <a href="https://maps.app.goo.gl/LexCP7Z82mnjxK1D6" rel="noreferrer" target="_blank">
                <div className="div-197 ">
                  Office Location
                  <br />
                  Noida, India
                </div></a>
              </span>
            </span>
          </div>
          <div className="column-38">
            <form ref={form} onSubmit={handleSubmit(onSubmit)}>
              <div className="div-198">
                <div className="div-199">
                  <div className="w-full">
                    <input
                      type="text"
                      placeholder="Your name*"
                      name="user_name"
                      {...register("user_name", {
                        required: true,
                      })}
                      className="font-semibold px-2 w-full span-64 form_input_color "
                    />
                    {errors.user_name && errors.user_name.type === "required" && ( 
                      <p className="errorMsg">Name is required.</p>
                    )}
                  </div>
                  <div className="w-full">
                    <input
                      type="text"
                      placeholder="Email"
                      name="user_email"
                      {...register("user_email", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      })}
                      className=" font-semibold px-2 w-full span-64 form_input_color"
                    />
                    {errors.user_email && errors.user_email.type === "required" && (
                      <p className="errorMsg">Email is required.</p>
                    )}
                    {errors.user_email && errors.user_email.type === "pattern" && (
                      <p className="errorMsg">Email is not valid.</p>
                    )}
                  </div>
                </div>
                <div className="div-200">
                  <input
                    type="number"
                    placeholder="Phone Number"
                    name="user_mobile"
                    {...register("user_mobile", {
                      required: false,
                    })}
                    className="font-semibold px-2 w-full span-64 form_input_color"
                  />
                  <span className="font-semibold px-0 w-full span-65">
                    <select
                      className="counter_select_background text-gray-400 w-full text-[15px]"
                      name="selectedType"
                      {...register("selectedType", {
                        required: false,
                      })}
                    >
                      <option value="I Am individual">I am an individual</option>
                      <option value="I am a company">I am a company</option>
                    </select>
                  </span>
                </div>
                <textarea
                  rows="5"
                  cols="50"
                  name="message"
                  placeholder="Message"
                  {...register("message", {
                    required: true,
                  })}
                  className="font-semibold px-2 outline-0 w-full textarea-68 form_input_color"
                />
                {errors.message && errors.message.type === "required" && (
                  <p className="errorMsg">Message is required.</p>
                )}

                <button type="submit" className="exploreButtonCss span-69">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
