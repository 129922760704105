import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { Pagination,Autoplay } from "swiper/modules";


const data = [
   {   id:1, image: "/images/certification/icon2.png" },
   {   id:2, image: "/images/certification/icon3.png" },
   {   id:3, image: "/images/certification/boat.png" },
   {   id:4, image: "/images/certification/icon5.png" },
   {   id:5, image: "/images/certification/icon1.png" },
  // {   id:6, image: "/images/certification/icon4.png" },
  //  {   id:7, image: "/images/certification/icon2.png" },
  //  {   id:8, image: "/images/certification/icon3.png" },
  //  {   id:9, image: "/images/certification/boat.png" },
  //  {   id:10, image: "/images/certification/icon5.png" },
  //  {   id:11, image: "/images/certification/icon1.png" },
  // {   id:12, image: "/images/certification/icon4.png" }
]
const Certification = () => {
  return (
    <div className='certification-main'>
      <h1 className="certification-h1"> Certification</h1>
      <div className="certification-swiper-div">
        <Swiper slidesPerView={3} spaceBetween={10} loop={true} pagination={{ clickable: true }} 
          modules={[Pagination]} className="mySwiper"
          breakpoints={{
            680: { slidesPerView: 5 },
            1080: { slidesPerView: 5 },
          }}
        >
          {data.map((item) => (
            <SwiperSlide key={item.id} className='mb-14 swiperslide'>
              <div className="certification-slider-div">
                <img src={item.image} alt="img"/>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default Certification
