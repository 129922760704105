import { Link, useLocation } from "react-router-dom";
import { CiMail } from "react-icons/ci";
import { IoMdCall } from "react-icons/io";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa6";
import { FiArrowLeft } from "react-icons/fi";
import { FiAlignJustify } from "react-icons/fi";
import {Helmet} from "react-helmet";
import { useState } from "react";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";

const Header = () => {
  const location = useLocation();

  const addMenuDropdown = () =>{
    const elems = document.querySelectorAll(".header_dropdown_custom_sub-menu");
    var index = 0, length = elems.length;
    for ( ; index < length; index++) {
        elems[index].style.opacity = 1;
        elems[index].style.transition = "all 0s";
    }
  };

  const closeMenuDropdown = () => {
   const elems = document.querySelectorAll(".header_dropdown_custom_sub-menu");
   var index = 0, length = elems.length;
   for ( ; index < length; index++) {
       elems[index].style.opacity = 0;
       elems[index].style.transition = "all 0s";
   }
   const mobileElem = document.querySelector(".header_dropdown_menu");
   const mobileElemOverlay = document.querySelector(".header_dropdown_custom_menu-overlay");
   mobileElem.classList.remove("active");
   mobileElemOverlay.classList.remove("active");
  };
 

  
  return (
   <>
    <div className="header-mainDiv" style={{background:(location.pathname === "/get-call") ? "#1E124B" : ""}}>
      <div className="header-mainDiv2 border-b-[1px] border-slate-700">
       <Link to="/">
       <div style={{ display: "flex" }}>
          <img src="/images/svaakLogo.png" alt="Svaak Software" className="header-mainDiv-img" />
          <div className="header-logo-div">
            <p className="ml-1"> Svaak</p> <span className="text-white ml-3"> S </span>
            <span className="text-[#21C3E8]"> o </span>
            <p className="text-white">ftware</p>
          </div>
        </div>
       </Link>

        <div className="header-section-1-mobile">
          <div >
         <button type="button" style={{color:"#fff", fontSize:"20px"}} className="hemburger_menu"><FiAlignJustify /></button>
          </div>
        </div>
        <div className="header-section-1">
         
          
          <div className="header-icon-div">
              <div className="header-icon-div2"> <CiMail /> </div>
              <a href={`mailto:info@svaaksoftware.com`}>
                <div>
                  <p style={{fontFamily:"Rubik"}}> Email </p> <p style={{fontFamily:"Rubik"}}> info@svaaksoftware.com </p>
                </div> 
              </a>
            </div>
            <div className="header-icon-div">
              <div className="header-icon-div2"> <IoMdCall /> </div>
              <div>
              <a href={`tel:${"+91 9818209683"}`}><p style={{fontFamily:"Rubik"}}> Call us </p> <p style={{fontFamily:"Rubik"}}> + 91 98182 09683 </p></a>
              </div>
            </div>
            <a href="https://maps.app.goo.gl/LexCP7Z82mnjxK1D6" rel="noreferrer" target="_blank">
              <div className="header-icon-div">
              
              <div className="header-icon-div2"> <MdOutlineLocationOn /> </div>
              <div>
                <p style={{fontFamily:"Rubik"}}> Office Location </p> <p style={{fontFamily:"Rubik"}}> Noida, India </p>
              </div>
            </div></a>

        </div>
      </div>
      <>
      <header className="header_dropdown_custom_header">
      <div className="header_dropdown_custom_container">
        <div className="header_dropdown_custom_row header_dropdown_custom_v-center">
        
          <div className="header_dropdown_custom_header-item header_dropdown_custom_item-center">
            <div className="header_dropdown_custom_menu-overlay"></div>
            <nav className="header_dropdown_menu">
              <div className="header_dropdown_custom_mobile-menu-head">
                <div className="header_dropdown_custom_go-back"><FiArrowLeft /></div>
                <div className="header_dropdown_custom_current-menu-title"></div>
                <div className="header_dropdown_custom_mobile-menu-close">&times;</div>
              </div>
              <ul className="header_dropdown_custom_menu-main">
                <li onClick={closeMenuDropdown}>
                  <Link to={"/technologies"}>Technologies</Link>
                </li>
                
                <li className="header_dropdown_custom_menu-item-has-children">
                  <a href="#" onMouseEnter={addMenuDropdown}>Services <i className="fa fa-angle-down"></i></a>
                  <div className="header_dropdown_custom_sub-menu header_dropdown_custom_single-column-menu header_dropdown_custom_single-column-menu_for_serviced">
                    <ul>
                    <li onClick={closeMenuDropdown}><Link to="/services/ux_ui_design"><img className="dropdown_image_icons" src="/images/service/ux.png" /> UX UI Designs</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/web_development"><img className="dropdown_image_icons" src="/images/service/web.png" /> Website Development</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/mobile_application"><img className="dropdown_image_icons" src="/images/service/mob.png" /> Mobile Application Development</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/digital_marketing"><img className="dropdown_image_icons" src="/images/service/bellright.png" /> Digital Marketing</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/cloud_devops"><img className="dropdown_image_icons" src="/images/service/cloud.png" /> Cloud & Devops</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/salesforce"><img className="dropdown_image_icons" src="/images/service/emptycloud.png" /> Salesforce Development</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/iot"><img className="dropdown_image_icons" src="/images/service/wifi.png" /> Internet of Things</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/data_analytics"><img className="dropdown_image_icons" src="/images/service/shape.png" /> Data Analytics</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/Artificial_Intelligence"><img className="dropdown_image_icons" src="/images/service/ai.png" /> Artificial Intelligence</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/blockchain"><img className="dropdown_image_icons" src="/images/service/bell.png" /> Blockchain</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/games_development"><img className="dropdown_image_icons" src="/images/service/game.png" /> Games Development</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/animations"><img className="dropdown_image_icons" src="/images/service/ring.png" /> Animations</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/mixedReality_ar_&_vr"><img className="dropdown_image_icons" src="/images/service/shape.png" /> Mixed Reality (AR & VR)</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/business_analysis"><img className="dropdown_image_icons" src="/images/service/updown.png" /> Business Analysis Services</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/qa_software_testing"><img className="dropdown_image_icons" src="/images/service/qa.png" /> QA and Software Testing</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/cyber_security"><img className="dropdown_image_icons" src="/images/service/you.png" /> Cyber Security</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/support_maintenance"><img className="dropdown_image_icons" src="/images/service/music.png" /> Support & Maintenance</Link></li>
                        <li onClick={closeMenuDropdown}><Link to="/services/project_management"><img className="dropdown_image_icons" src="/images/service/aie.png" /> Project Management Trainings</Link></li>
                    </ul>
                  </div>
                </li>
                <li className="header_dropdown_custom_menu-item-has-children">
                  <a href="#" onMouseEnter={addMenuDropdown}>Industries <i className="fa fa-angle-down"></i></a>
                  <div className="header_dropdown_custom_sub-menu header_dropdown_custom_single-column-menu">
                    <ul>
                      <li onClick={closeMenuDropdown}><Link to="/industries/banking"><img className="dropdown_image_icons" src="/images/iconDropdown/bank.png"/>Banking & Finance</Link></li>
                      <li onClick={closeMenuDropdown}><Link to="/industries/telecome"><img className="dropdown_image_icons" src="/images/iconDropdown/telecom.png"/>Telecom & Media</Link></li>
                      <li onClick={closeMenuDropdown}><Link to="/industries/healthcare"><img className="dropdown_image_icons" src="/images/iconDropdown/Healthcare.png"/> Healthcare</Link> </li>
                      <li onClick={closeMenuDropdown}><Link to="/industries/hospitality"><img className="dropdown_image_icons" src="/images/iconDropdown/Hospitality.png"/>Hospitality</Link></li>
                      <li onClick={closeMenuDropdown}><Link to="/industries/tourism"><img className="dropdown_image_icons" src="/images/iconDropdown/Transport.png"/> Tourism</Link></li>
                      <li onClick={closeMenuDropdown}><Link to="/industries/ecommerce"><img className="dropdown_image_icons" src="/images/iconDropdown/eCommerce.png"/>E-Commerce</Link></li>
                      <li onClick={closeMenuDropdown}><Link to="/industries/elearning"><img className="dropdown_image_icons" src="/images/iconDropdown/Elearning.png"/>E- Learning</Link></li>
                      <li onClick={closeMenuDropdown}><Link to="/industries/transportation_&_Logistics"><img className="dropdown_image_icons" src="/images/iconDropdown/Transport&Logistic.png"/>Transportation & Logistics</Link></li>
                      <li onClick={closeMenuDropdown}><Link to="/industries/manufacturing"><img className="dropdown_image_icons" src="/images/iconDropdown/Manufacturing.png"/>Manufacturing</Link></li>
                    </ul>
                  </div>
                </li>
                <li className="header_dropdown_custom_menu-item-has-children">
                  <a href="#" onMouseEnter={addMenuDropdown}>Pricing Plans <i className="fa fa-angle-down"></i></a>
                  <div className="header_dropdown_custom_sub-menu header_dropdown_custom_single-column-menu">
                    <ul>
                      <li onClick={closeMenuDropdown}><Link to="/pricing_plans/development_pricings"><img className="dropdown_image_icons" src="/images/iconDropdown/bank.png"/>Project Development Pricings</Link></li>
                      <li onClick={closeMenuDropdown}><Link to="/pricing_plans/hire_us"><img className="dropdown_image_icons" src="/images/iconDropdown/telecom.png"/>Hire Professional Pricings</Link></li>
                    </ul>
                  </div>
                </li>
                <li onClick={closeMenuDropdown}><Link to="/about-us">About Us</Link></li>
                <div >
                <p className="header__Portfolio header_dropdown_for_mobile_icon_portfolio" style={{color:"#000", marginLeft:"1.8rem",marginTop:"1.8rem"}} onClick={closeMenuDropdown} ><Link to={"/portfolio"} style={{fontFamily:"Rubik"}}> Portfolio </Link></p>
                <div className="header_dropdown_for_mobile_icon">
                
                <a href="#"> <FaXTwitter /></a>
                <a href="https://www.facebook.com/SVAAKSoftware/" target="_blank" rel="noreferrer"> <FaFacebookF /></a>
            <a href="#"> <FaXTwitter /></a>
          <a href="https://www.linkedin.com/company/svaak-software-technology-pvt-ltd/mycompany/" target="_blank" rel="noreferrer"><FaLinkedin /></a>
                </div>
          </div>
              </ul>
      
            </nav>
          </div>
          <p className="header__Portfolio icon_for_desktop_show cursor-pointer"><Link to={"/portfolio"} style={{fontFamily:"Rubik"}}> Portfolio </Link></p>
          <div className="header_dropdown_custom_header-item icon_for_desktop_show">
          <a className="icon_for_desktop_show" href="https://www.facebook.com/SVAAKSoftware/" target="_blank" rel="noreferrer"> <FaFacebookF /></a>
            <a className="icon_for_desktop_show" href="#"> <FaXTwitter /></a>
          <a className="icon_for_desktop_show" href="https://www.linkedin.com/company/svaak-software-technology-pvt-ltd/mycompany/" target="_blank" rel="noreferrer"><FaLinkedin /></a>
          </div>
          <div>
          <Link to="/get-call"><div className="header-section2-getCall getHireUsButtonCss">{`Let's`} Connect</div></Link>
          </div>
        </div>
      </div>
    </header>
    
    </>
    </div>
    <Helmet>
      <script src="/customJs/fixedHeader.js" type="text/javascript" />
      <script src="/customJs/headerdropdown.js" type="text/javascript" />
    </Helmet>
    </>   
  );
};

export default Header;

// import { PiUsersBold } from "react-icons/pi";

// const data1 = [
//   { id: 1, icon: <CiMail />, social: "Email", social_id: "info@svaaksoftware.com" },
//   {
//     id: 2,
//     icon: <IoMdCall />,
//     social: "Call Us",
//     social_id: "+ 91 98182 09683",
//   },
//   {
//     id: 3,
//     icon: <MdOutlineLocationOn />,
//     social: "Office Location",
//     social_id: "Noida, India",
//     hover: true
//   },
// ];

{/* <li className="header_dropdown_custom_menu-item-has-children" >
                  <a href="#" onMouseEnter={addMenuDropdown}>Services<i className="fa fa-angle-down"></i></a>
                  <div className="header_dropdown_custom_sub-menu header_dropdown_custom_mega-menu header_dropdown_custom_mega-menu-column-4">
                    <div className="header_dropdown_custom_list-item">
                      <ul>
                        <li onClick={closeMenuDropdown}><img className="header_dropdown_custom_list-item_images" style={{width:"20px"}} src="/images/service/ux.png" /><Link to="/services/ux_ui_design">UX UI Designs</Link></li>
                        <li onClick={closeMenuDropdown}><img className="header_dropdown_custom_list-item_images" src="/images/service/web.png" /><Link to="/services/web_development">Website Development</Link></li>
                        <li onClick={closeMenuDropdown}><img className="header_dropdown_custom_list-item_images" src="/images/service/mob.png" /><Link to="/services/mobile_application">Mobile Application Development</Link></li>
                        <li onClick={closeMenuDropdown}><img className="header_dropdown_custom_list-item_images" src="/images/service/bellright.png" /><Link to="/services/digital_marketing">Digital Marketing</Link></li>
                        <li onClick={closeMenuDropdown}><img className="header_dropdown_custom_list-item_images" src="/images/service/cloud.png" /><Link to="/services/cloud_devops">Cloud & Devops</Link></li>
                        <li onClick={closeMenuDropdown}><img className="header_dropdown_custom_list-item_images" src="/images/service/emptycloud.png" /><Link to="/services/salesforce">Salesforce Development</Link></li>
                        <li onClick={closeMenuDropdown}><img className="header_dropdown_custom_list-item_images" src="/images/service/wifi.png" /><Link to="/services/iot">Internet of Things</Link></li>
                        <li onClick={closeMenuDropdown}><img className="header_dropdown_custom_list-item_images" src="/images/service/shape.png" /><Link to="/services/data_analytics">Data Analytics</Link></li>
                        <li onClick={closeMenuDropdown}><img className="header_dropdown_custom_list-item_images" src="/images/service/ai.png" /><Link to="/services/Artificial_Intelligence">Artificial Intelligence</Link></li>
                      </ul>
                    </div>
                    <div className="header_dropdown_custom_list-item">
                      <ul>
                        <li onClick={closeMenuDropdown}><img src="/images/service/bell.png" /><Link to="/services/blockchain"> Blockchain</Link></li>
                        <li onClick={closeMenuDropdown}><img src="/images/service/game.png" /><Link to="/services/games_development"> Games Development</Link></li>
                        <li onClick={closeMenuDropdown}><img src="/images/service/ring.png" /><Link to="/services/animations"> Animations</Link></li>
                        <li onClick={closeMenuDropdown}><img src="/images/service/shape.png" /><Link to="/services/mixedReality_ar_&_vr"> Mixed Reality (AR & VR)</Link></li>
                        <li onClick={closeMenuDropdown}><img src="/images/service/updown.png" /><Link to="/services/business_analysis"> Business Analysis Services</Link></li>
                        <li onClick={closeMenuDropdown}><img src="/images/service/qa.png" /><Link to="/services/qa_software_testing"> QA and Software Testing</Link></li>
                        <li onClick={closeMenuDropdown}><img src="/images/service/you.png" /><Link to="/services/cyber_security"> Cyber Security</Link></li>
                        <li onClick={closeMenuDropdown}><img src="/images/service/music.png" /><Link to="/services/support_maintenance"> Support & Maintenance</Link></li>
                        <li onClick={closeMenuDropdown}><img src="/images/service/aie.png" /><Link to="/services/project_management"> Project Management Trainings</Link></li>
                      </ul>
                    </div>
                  </div>
                </li> */}

                 {/* {data1.map((item, index) => (
            <div className="header-icon-div" key={index}>
              <div className="header-icon-div2">{item.icon}</div>
              <div>
                <p style={{fontFamily:"Rubik"}}> {item.social} </p> <p style={{fontFamily:"Rubik"}}>{item.social_id}</p>
              </div>
            </div>
          ))} */}