import React from 'react';


const WhyUsSolution = ({whyUsSolutionData}) => {
  const {title,peragraph,imageOne,imageTwo,lists} = whyUsSolutionData;
  return (
    <>
         {/* <img
      loading="lazy"
      src="/images/footerAnimate.gif"
      className="img-30"
    /> */}
    <div className="img-30 bg-[#091242]"></div>
     <div className="div-151">
    <div className="div-152">
      <div className="div-153">
        <div className="column-33">
          <span className="span-44">
            <div className="div-154">
              <div className="div-155"></div>
              <span className="span-45">Why Us</span>
            </div>
            <div className="div-156">{title}</div>
            <div className="div-157">{peragraph}</div>
            {lists && lists.map(({id,icon,title}) => (
              <span className="span-46" key={id}>
              <img
                loading="lazy"
                src={icon}
                className="img-31"
              />
              <div className="div-158">{title}</div>
            </span>
            ))}
           
            {/* <span className="span-47">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/788db96a7284a1b6902b58e0a754c74cb18bc7e877a5b5f0262ee9769811a9b3?"
                className="img-32"
              />
              <div className="div-159">Effective Communication</div>
            </span>
            <span className="span-48">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6d84705653580b0a3396116f6885c02192e78f54e411c146fc4e808295d1f2e?"
                className="img-33"
              />
              <div className="div-160">Optimized Tech Cost</div>
            </span>
            <span className="span-49">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6d84705653580b0a3396116f6885c02192e78f54e411c146fc4e808295d1f2e?"
                className="img-34"
              />
              <div className="div-161">Agile Framework</div>
            </span>
            <span className="span-50">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6d84705653580b0a3396116f6885c02192e78f54e411c146fc4e808295d1f2e?"
                className="img-35"
              />
              <div className="div-162">Access to more Talent</div>
            </span> */}
          </span>
        </div>
        <div className="column-34 whyusPic">
          <img
            loading="lazy"
            src={imageOne}
            className="img-36"
          />
          <img
            loading="lazy"
            src={imageTwo}
            className="imgwhyusPic1"
          />
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default WhyUsSolution
