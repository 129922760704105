import { Link } from 'react-router-dom';
const Errorpage = () => {
  return (
    <div className="underConstruction_section flex flex-col items-center">
      <img
        loading="lazy"
        src="/images/notfound.png"
        className="h-[50vh] flex justify-center"
      />
      <Link to="/" className="goback_btn_under_construction">
        <div className="underConstruction_goback">Go Back</div>
      </Link>
    </div>
  );
};

export default Errorpage;
