const baseUrl = "http://localhost:9500/api/v1";
// assuming top-level await for brevity
import emailjs from '@emailjs/browser';



const sendNodemailerEmail = async (form) => {
    try {
      await emailjs.sendForm('service_b5vv60d', 'template_4nm8yj8', form, {
        publicKey: 'QQnXWk3f4_BW4sO7r',
      })
       return true;
    }
    catch (error) {
        console.log('FAILED...', error);
    }
  }


export {baseUrl,sendNodemailerEmail}